










































































import Vue from 'vue'

import { Component, Watch } from 'vue-property-decorator'
import VLoadSpinner from '~/components/VLoadSpinner.vue'
import { UserStore } from '@/store/user'
import { ModalStore } from '~/store/modal'
import { BASE_URL } from '~/const/base-url'
import { disconnectMailChimpApi, getMailChimpMarketingAudiences, saveMailChimpCredentials } from '#/api/mailchimp'
import { updateProducer } from '#/api/profile'
import { disconnectGetresponseApi, getGetresponseMarketingAudiences, saveGetresponseCredentials } from '#/api/getresponse'
import { disconnectAweberApi, getAweberMarketingAudiences, saveAweberCredentials } from '#/api/aweber'

enum AudienceType {
    Mailchimp,
    Getresponse,
    Aweber
}

@Component({ components: { VLoadSpinner } })
export default class EmailMarketingPage extends Vue {
    AudienceType = AudienceType
    mailchimpMarketingAudiences: MarketingAudience[] = []
    getresponseMarketingAudiences: MarketingAudience[] = []
    aweberMarketingAudiences: MarketingAudience[] = []
    openMenuDropDown: AudienceType = null

    @ModalStore.State('isLoading') isLoading: boolean
    @UserStore.State('authenticatedUser') authenticatedUser: IUserProducer
    @ModalStore.Mutation('SET_LOADING_STATE') SET_LOADING_STATE: (value: boolean) => void
    @UserStore.Action('fetchUserProducerInfo') fetchUserProducerInfo: () => void

    toggleMenuDropdown(menuToOpen: AudienceType): void {
        if (this.openMenuDropDown === menuToOpen) this.openMenuDropDown = null
        else this.openMenuDropDown = menuToOpen
    }

    closeMenuDropdown(): void {
        this.openMenuDropDown = null
    }

    async refreshMarketingAudiences() {
        this.mailchimpMarketingAudiences = await getMailChimpMarketingAudiences()
        this.getresponseMarketingAudiences = await getGetresponseMarketingAudiences()
        this.aweberMarketingAudiences = await getAweberMarketingAudiences()
    }

    connectAudienceType(audienceType: AudienceType) {
        switch (audienceType) {
            case AudienceType.Mailchimp:
                location.assign(`${BASE_URL}/mailchimp/auth`)
                break
            case AudienceType.Getresponse:
                location.assign(`${BASE_URL}/getresponse/auth`)
                break
            case AudienceType.Aweber:
                location.assign(`${BASE_URL}/aweber/auth`)
                break
        }
    }

    async disconnectAudienceType(audienceType: AudienceType) {
        this.SET_LOADING_STATE(true)
        switch (audienceType) {
            case AudienceType.Mailchimp:
                await disconnectMailChimpApi()
                break
            case AudienceType.Getresponse:
                await disconnectGetresponseApi()
                break
            case AudienceType.Aweber:
                await disconnectAweberApi()
                break
        }
        await this.refreshMarketingAudiences()
        this.SET_LOADING_STATE(false)
    }

    async updateProducerMarketingAudience(audienceId: string, audienceType: AudienceType) {
        switch (audienceType) {
            case AudienceType.Mailchimp:
                await updateProducer({ id: this.authenticatedUser.id, mailchimp_audience_id: audienceId })
                break
            case AudienceType.Getresponse:
                await updateProducer({ id: this.authenticatedUser.id, getresponse_campaign_id: audienceId })
                break
            case AudienceType.Aweber:
                await updateProducer({ id: this.authenticatedUser.id, aweber_list_id: audienceId })
                break
        }
        await this.fetchUserProducerInfo()
        this.closeMenuDropdown()
    }

    getSelectedAudienceName(audienceType: AudienceType) {
        switch (audienceType) {
            case AudienceType.Mailchimp:
                return this.mailchimpMarketingAudiences.find(it => it.id === this.authenticatedUser.mailchimp_audience_id)?.name ?? 'Unknown'
            case AudienceType.Getresponse:
                return this.getresponseMarketingAudiences.find(it => it.id === this.authenticatedUser.getresponse_campaign_id)?.name ?? 'Unknown'
            case AudienceType.Aweber:
                return this.aweberMarketingAudiences.find(it => it.id.toString() === this.authenticatedUser.aweber_list_id)?.name ?? 'Unknown'
        }
    }

    @Watch('$route', { immediate: true })
    async route() {
        const mailchimpAccessToken = this.$route.query.mailchimpAccessToken as string
        const serverPrefix = this.$route.query.serverPrefix as string

        if (mailchimpAccessToken && serverPrefix) {
            this.SET_LOADING_STATE(true)
            await saveMailChimpCredentials(mailchimpAccessToken, serverPrefix)
            await this.refreshMarketingAudiences()
            if (this.mailchimpMarketingAudiences.length > 0)
                await this.updateProducerMarketingAudience(this.mailchimpMarketingAudiences[0].id, AudienceType.Mailchimp)
            this.SET_LOADING_STATE(false)
        }

        const getresponseAccessToken = this.$route.query.getresponseAccessToken as string
        const getresponseRefreshToken = this.$route.query.getresponseRefreshToken as string

        if (getresponseAccessToken && getresponseRefreshToken) {
            this.SET_LOADING_STATE(true)
            await saveGetresponseCredentials(getresponseAccessToken, getresponseRefreshToken)
            await this.refreshMarketingAudiences()
            if (this.getresponseMarketingAudiences.length > 0)
                await this.updateProducerMarketingAudience(this.getresponseMarketingAudiences[0].id, AudienceType.Getresponse)
            this.SET_LOADING_STATE(false)
        }

        const aweberAccessToken = this.$route.query.aweberAccessToken as string
        const aweberRefreshToken = this.$route.query.aweberRefreshToken as string

        if (aweberAccessToken && aweberRefreshToken) {
            this.SET_LOADING_STATE(true)
            await saveAweberCredentials(aweberAccessToken, aweberRefreshToken)
            await this.refreshMarketingAudiences()
            if (this.aweberMarketingAudiences.length > 0) await this.updateProducerMarketingAudience(this.aweberMarketingAudiences[0].id, AudienceType.Aweber)
            this.SET_LOADING_STATE(false)
        }
    }

    async mounted() {
        this.SET_LOADING_STATE(true)
        await this.refreshMarketingAudiences()
        this.SET_LOADING_STATE(false)
    }
}
