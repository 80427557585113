import { get, post } from '~/services/http-requests'

export const getGetresponseMarketingAudiences = async (): Promise<MarketingAudience[]> => {
    try {
        return await get({
            baseProperty: '',
            fullUrl: `getresponse/marketing_audiences`,
            isFreeRequest: true
        })
    } catch (e) {
        return []
    }
}

export const saveGetresponseCredentials = (accessToken: string, refreshToken: string): Promise<void> => post({
    baseProperty: '',
    fullUrl: `getresponse/save_credentials/`,
    isFreeRequest: true,
    dispatchParams: { access_token: accessToken, refresh_token: refreshToken }
})

export const disconnectGetresponseApi = (): Promise<void> => post({
    baseProperty: '',
    fullUrl: `getresponse/disconnect/`,
    isFreeRequest: true
})
