import { get, post } from '~/services/http-requests'

export const getMailChimpMarketingAudiences = async (): Promise<MarketingAudience[]> => {
    try {
        return await get({ baseProperty: '', fullUrl: `mailchimp/marketing_audiences`, isFreeRequest: true })
    } catch (e) {
        return []
    }
}

export const saveMailChimpCredentials = (accessToken: string, serverPrefix: string): Promise<void> =>
    post({
        baseProperty: '',
        fullUrl: `mailchimp/save_credentials/`,
        isFreeRequest: true,
        dispatchParams: { access_token: accessToken, server_prefix: serverPrefix }
    })

export const disconnectMailChimpApi = (): Promise<void> => post({ baseProperty: '', fullUrl: `mailchimp/disconnect/`, isFreeRequest: true })
